/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.background {
  background: rgb(106, 153, 195);
}

.one {
  background: rgb(143, 187, 228);
  box-shadow: 0 12px 100px grey;
  backdrop-filter: blur(7.5px);
}

.custom-bg {
  background: rgb(11, 113, 196);
}

.home {
  background: rgb(11, 113, 196);
  box-shadow: 0 12px 100px grey;
  backdrop-filter: blur(7.5px);
}

.glass {
  background: rgb(143, 187, 228);
  box-shadow: 0 12px 100px grey;
  backdrop-filter: blur(7.5px);
}

.back {
  font-size: xx-large;
  background: rgb(143, 187, 228);
  box-shadow: 0 12px 100px grey;
  backdrop-filter: blur(7.5px);
}

.recruit,
.page {
  padding: 32px;
  margin-top: 32px;
  margin-bottom: auto;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 60px;
}

.carousel .slide {
  background: none;
}
.carousel .control-dots .dot {
  background: #383d41;
  width: 12px;
  height: 12px;
  margin: 0 8px;
}
.carousel .control-dots .dot.selected {
  background: #33363c;
}
